import { Button, Text, useDimensions } from '@freight-nexus/ui';
import React from 'react';

import * as Styled from './styles';

interface JoinProps {
  openModal: () => void;
}

export const Join = ({ openModal }: JoinProps) => {
  const { isTablet } = useDimensions();
  return (
    <Styled.Container>
      <Text type="display-h1" centered>
        Join the freight revolution.
      </Text>

      <Text type="text-t1" centered>
        Grow your relationships, optimize your operations, and conquer the
        market with FreightNexus.&nbsp;
        {!isTablet && <br />}
        Sign up now to join our exclusive waitlist and be the first to
        experience the future of freight management.
      </Text>

      <Button onClick={openModal}>Join the waitlist</Button>
    </Styled.Container>
  );
};
