import { BREAKPOINTS } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const Navbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 20px 20px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    justify-content: center;
    padding: 12px 0;
  }

  :nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;

    :first-child {
      margin-right: 20px;
    }
  }
`;

export const Content = styled.div`
  margin: 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  > :first-child {
    max-width: 1080px;
  }

  > :nth-child(2) {
    max-width: 980px;
    margin: 20px 0 40px 0;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin: 120px 20px;
  }
`;

export const GlobeContainer = styled.div`
  position: absolute;
  height: 120vh;
  width: 120vh;
  bottom: 5vw;
  left: 50%;
  transform: translate(-50%, 50%);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    height: 120vh;
    width: 120vh;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    height: 120vh;
    width: 120vh;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    height: 800px;
    width: 800px;
  }
`;
