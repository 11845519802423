import { Text } from '@freight-nexus/ui';
import React from 'react';

import Documentation from '../../assets/documentation.svg';
import Features from '../../assets/features.svg';
import Matchmaking from '../../assets/matchmaking.svg';
import Trust from '../../assets/trust.svg';

import * as Styled from './styles';

const tiles = [
  {
    icon: <Styled.Icon src={Matchmaking} />,
    title: 'Effortless matchmaking',
    description:
      'Bid farewell to endless scrolling and guesswork. Our intelligent platform connects you with the perfect match, streamlining negotiations and saving you valuable time.'
  },
  {
    icon: <Styled.Icon src={Trust} />,
    title: 'Built-in trust',
    description:
      'Say no to double brokering and load theft. We vet carriers and brokers rigorously, ensuring every transaction is secure and transparent.'
  },
  {
    icon: <Styled.Icon src={Documentation} />,
    title: 'Seamless documentation',
    description:
      "Simplify paperwork with AI-powered documentation management. From invoices to proof of delivery, we've got you covered every step of the way."
  },
  {
    icon: <Styled.Icon src={Features} />,
    title: 'Future-proof features',
    description:
      'Stay ahead of the curve with features like location forecasting, ELD integration, and AI-driven negotiation agents. Our platform evolves with you, anticipating your needs before you even know them.'
  }
];

export const Solution = () => {
  return (
    <Styled.Container>
      <Text type="display-h1" centered>
        Your all-in-one freight solution
      </Text>
      <Styled.Tiles>
        {tiles.map((tile, index) => (
          <Tile key={index} {...tile} />
        ))}
      </Styled.Tiles>
    </Styled.Container>
  );
};

interface TileProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Tile = ({ icon, title, description }: TileProps) => {
  return (
    <Styled.Tile>
      <Styled.IconContainer>{icon}</Styled.IconContainer>
      <Text type="display-h2">{title}</Text>
      <Styled.Description>
        <Text type="text-t1">{description}</Text>
      </Styled.Description>
    </Styled.Tile>
  );
};
