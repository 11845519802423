import React from 'react';
import styled from 'styled-components';

import { useDimensions } from '../../hooks/useDimensions';
import LogoSVG from '../../icons/logo.svg';
import { Text } from '../text';

const fontSizeMap = {
  sm: 18,
  md: 20,
  lg: 24
};

const svgSizeMap = {
  sm: 24,
  md: 30,
  lg: 36
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :not(:last-child) {
    margin-right: 6px;
  }
  user-select: none;
`;

const SVG = styled.img<{
  size?: 'sm' | 'md' | 'lg';
}>`
  height: ${({ size = 'md' }) => svgSizeMap[size]}px;
  padding-top: 8px;
  width: auto;
`;

interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
}

export const Logo = ({ size = 'md' }: LogoProps) => {
  const { isTablet } = useDimensions();

  if (isTablet) {
    size = 'md';
  }
  return (
    <Container>
      <SVG src={LogoSVG} alt="logo" />
      <Text type="display-h1" size={fontSizeMap[size]} weight={800}>
        FreightNexus
      </Text>
    </Container>
  );
};
