import styled from 'styled-components';

export interface SelectProps {
  error?: boolean;
}

export const Select = styled.select<SelectProps>`
  width: 100%;
  height: 44px;
  padding: 0 12px;
  border-radius: 10px;
  font: ${({ theme }) => theme.textStyles.desktop['text-t2']};
  box-sizing: border-box;
  margin: 0px;

  outline: none;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-500']};

  &:focus {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-600']};
  }
  &:hover {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-600']};
  }
  outline-color: ${({ theme }) => theme.palette['grey-500']};

  // change select arrow
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
`;
