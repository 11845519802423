import { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../styles';

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...dimensions,
    isTablet: dimensions.width < BREAKPOINTS.DESKTOP,
    isMobile: dimensions.width < BREAKPOINTS.TABLET
  };
};
