import { BREAKPOINTS } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette['grey-800']};
  padding: 85px 100px 140px 0;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.palette.white};

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 45px 50px 80px 0;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 45px 20px 45px 0;
  }
`;

export const Contact = styled.div`
  > :not(:last-child) {
    margin-bottom: 4px;
  }

  * {
    text-align: right;
  }
`;
