import { FreightStatus, ICapacity } from '@freight-nexus/db/src/types';

import * as Transport from '../transport';

export const route = '/capacity';

export namespace Create {
  export const name = 'Create';

  export interface Input extends Transport.JSONSerializableObject {
    availableStart: Date;
    availableEnd: Date;
    email?: string;
    origin: string;
    destination: string;
    equipment: string;
    weightLimit: number | null;
    targetRate: number | null;
    notes: string | null;
    assignedUserId?: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    capacity: ICapacity;
  }
}

export namespace GetAll {
  export const name = 'GetAll';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    capacities: ICapacity[];
  }
}

export namespace GetAllUnmatched {
  export const name = 'GetAllUnmatched';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    capacities: ICapacity[];
  }
}
export namespace UpdateStatus {
  export const name = 'UpdateStatus';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
    status: FreightStatus;
  }

  export interface Output extends Transport.JSONSerializableObject {
    capacity: ICapacity;
  }
}

export namespace DeleteById {
  export const name = 'DeleteById';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
  }

  export interface Output extends Transport.JSONSerializableObject {}
}
