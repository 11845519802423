import { ICapacity, IFreight, IUser } from '@freight-nexus/db/types';

import * as Transport from '../transport';

export const route = '/user';

export namespace GetMe {
  export const name = 'GetMe';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    user: IUser;
  }
}

export namespace GetById {
  export const name = 'GetById';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    user?: IUser;
  }
}

export namespace GetFreight {
  export const name = 'GetFreight';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    freight: IFreight[];
  }
}

export namespace GetCapacity {
  export const name = 'GetCapacity';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    capacity: ICapacity[];
  }
}

export namespace GetUsers {
  export const name = 'GetUsers';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    users: IUser[];
  }
}

export namespace Update {
  export const name = 'Update';

  export interface Input extends Transport.JSONSerializableObject {
    diff: Partial<Omit<IUser, 'createdAt' | 'updatedAt' | 'isAdmin' | 'password' | 'type'>>;
  }

  export interface Output extends Transport.JSONSerializableObject {
    user: IUser;
  }
}