import { IPalette } from '../types';

export const palette: IPalette = {
  white: '#ffffff',
  black: '#000000',
  primary: '#EF962D',
  primaryLight: '#ebcaa4',
  red: '#ff4a6b',
  'grey-800': '#353535',
  'grey-700': '#757575',
  'grey-600': '#BDBDBD',
  'grey-500': '#DEDEDE'
};
