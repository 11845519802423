import { IExtractedData } from '@freight-nexus/db/src/types';

import * as Transport from '../transport';

export const route = '/ai';

export namespace ExtractLoadInfo {
  export const name = 'ExtractLoadInfo';

  export interface Input extends Transport.JSONSerializableObject {
    email: string;
    text: string;
  }
  export interface Output extends Transport.JSONSerializableObject {
    loads: Partial<Omit<IExtractedData, 'id' | 'emailId' | 'createdAt'>>[];
    error?: string;
  }
}
