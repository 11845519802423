import { Text } from '@freight-nexus/ui';
import React from 'react';

import Unsplash from '../../assets/unsplash.svg';

import * as Styled from './styles';

export const Unlock = () => {
  return (
    <Styled.Container>
      <Text type="display-h1" centered>
        Unlock the power of relationships and technology
      </Text>
      <Styled.Img src={Unsplash} />
      <Styled.Description>
        <Text type="text-t1" centered>
          In a market dominated by small firms and fierce competition, success
          hinges on strong relationships. FreightNexus bridges the gap between
          brokers and carriers, empowering you with advanced AI tools to not
          just survive, but thrive.
        </Text>
      </Styled.Description>
    </Styled.Container>
  );
};
