import { CompanyType } from '@freight-nexus/db/types';
import { Button, Input, Modal, Text } from '@freight-nexus/ui';
import React, { useEffect, useState } from 'react';

import { WaitlistAPI } from '../../api';

import * as Styled from './styles';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

interface SignUpModalProps {
  visible: boolean;
  onClose: () => void;
}

export const SignUpModal = ({ visible, onClose }: SignUpModalProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [type, setType] = useState<CompanyType | null>(null);
  const [emailError, setEmailError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      setName('');
      setEmail('');
      setCompany('');
      setType(null);
      setEmailError(false);
      setSubmitError(false);
      setSuccess(false);
    }
  }, [visible]);

  const handleSubmit = async () => {
    if (!name || !email || !company || !type || submitting) {
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError(true);
      return;
    }
    setSubmitting(true);
    setSubmitError(false);
    setEmailError(false);
    const res = await WaitlistAPI.signUp({
      email,
      name,
      company,
      companyType: type!
    });
    if (res?.success) {
      setSuccess(true);
    } else {
      setSubmitError(true);
    }
    setSubmitting(false);
  };

  return visible ? (
    <Modal onClose={onClose}>
      <Styled.Container>
        {!success ? (
          <>
            <Text type="display-h2" centered>
              Get started with FreightNexus
            </Text>
            <Text type="display-h3" centered secondary>
              Please fill out the form below.
            </Text>
            <Styled.Form>
              <Styled.InputGroup>
                <Text type="text-t3" secondary>
                  Full name
                </Text>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </Styled.InputGroup>
              <Styled.InputGroup>
                <Text type="text-t3" secondary>
                  Email
                </Text>
                <Input
                  error={emailError}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Styled.InputGroup>
              <Styled.InputGroup>
                <Text type="text-t3" secondary>
                  Company name
                </Text>
                <Input
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </Styled.InputGroup>
              <Styled.InputGroup>
                <Text type="text-t3" secondary>
                  Company type
                </Text>
                <Styled.CompanyTypes>
                  {Object.values(CompanyType).map((companyType) => (
                    <Button
                      action={companyType === type ? 'primary' : 'secondary'}
                      onClick={() =>
                        companyType === type
                          ? setType(null)
                          : setType(companyType)
                      }
                      width="100%"
                      size="md"
                    >
                      {companyType}
                    </Button>
                  ))}
                </Styled.CompanyTypes>
              </Styled.InputGroup>
            </Styled.Form>
            {submitError && (
              <Text type="display-h3" color="red" centered>
                There was an error submitting the form. Please try again.
              </Text>
            )}
            <Styled.SubmitButton>
              <Button
                size="md"
                disabled={!name || !email || !company || !type || submitting}
                onClick={handleSubmit}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Styled.SubmitButton>
          </>
        ) : (
          <>
            <Text type="display-h2" centered>
              Thank you for signing up!
            </Text>
            <Text type="display-h3" centered secondary>
              We will be in touch with updates.
            </Text>
            <Styled.SubmitButton>
              <Button size="md" onClick={onClose}>
                Close
              </Button>
            </Styled.SubmitButton>
          </>
        )}
      </Styled.Container>
    </Modal>
  ) : null;
};
