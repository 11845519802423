import { IEmail, IExtractedData} from "@freight-nexus/db/src/types";

import * as Transport from "../transport";

export const route = '/email';

export namespace Create {
  export const name = 'Create';

  export interface Input extends Transport.JSONSerializableObject {
    name: string | null;
    address: string;
    subject: string | null;
    body: string | null;
    bodyHtml: string | null;
    attachmentsUrl: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    email: IEmail;
  }
}

export namespace GetById {
  export const name = 'GetById';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    email?: IEmail;
    extractedData?: IExtractedData[];
  }
}

export namespace GetAll {
  export const name = 'GetAll';

  export interface Input extends Transport.JSONSerializableObject {
  }

  export interface Output extends Transport.JSONSerializableObject {
    emails: IEmail[];
  }
}

export namespace DeleteById {
  export const name = 'DeleteById';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
  }
}

export namespace GetUnprocessedEmails {
  export const name = 'GetUnprocessedEmails';

  export interface Input extends Transport.JSONSerializableObject {
  }

  export interface Output extends Transport.JSONSerializableObject {
    emails: IEmail[];
  }
}

export namespace CreateAndProcessEmail {
  export const name = 'CreateAndProcessEmail';

  export interface Input extends Transport.JSONSerializableObject {
    name: string | null;
    address: string;
    subject: string | null;
    body: string | null;
    bodyHtml: string | null;
    attachmentsUrl: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    email: IEmail;
  }
}