import { Text } from '@freight-nexus/ui';
import React from 'react';

import * as Styled from './styles';

export const Footer = () => {
  return (
    <Styled.Container>
      <Styled.Contact>
        <Text type="display-h2" size={20}>
          CONTACT
        </Text>
        <Text type="text-t2">team@freightnexus.ai</Text>
      </Styled.Contact>
    </Styled.Container>
  );
};
