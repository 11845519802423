import React, { useEffect } from 'react';
import styled from 'styled-components';

import Close from '../../icons/close.svg';
import { MODAL_ZINDEX, OVERLAY_ZINDEX } from '../../styles/zindex';
import { Text } from '../text';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${OVERLAY_ZINDEX};

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: auto;
  display: flex;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: appear 0.2s ease-in;
`;

interface ContainerProps {
  width: string | number;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: ${MODAL_ZINDEX};
  background-color: ${({ theme }) => theme.palette.white};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  max-width: 90vw;
  max-height: 80vh;
  margin: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const Header = styled.div`
  padding: 20px 20px 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
`;

export interface ModalProps {
  width?: string | number;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal = ({
  width = 550,
  title,
  onClose,
  children
}: ModalProps) => {
  useEffect(() => {
    // prevent horizontal shift from scrollbar disappearing
    const actualWidth = document.body.offsetWidth;
    const declaredWidth = document.body.style.width;
    document.body.style.overflow = 'hidden';
    document.body.style.width = `${actualWidth}px`;
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.width = declaredWidth;
    };
  }, []);
  return (
    <Overlay
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <Container
        width={width}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Header>
          {title ? <Text type="display-h2">{title}</Text> : <div />}
          <CloseButton src={Close} onClick={onClose} />
        </Header>
        <Body>{children}</Body>
      </Container>
    </Overlay>
  );
};
