import { Button, Logo, Text, useDimensions } from '@freight-nexus/ui';
import React, { useMemo } from 'react';

import { World } from '../../components/Globe';

import * as Styled from './styles';

interface HeroProps {
  openModal: () => void;
}

export const Hero = ({ openModal }: HeroProps) => {
  const { isTablet, isMobile } = useDimensions();

  const memoizedGlobe = useMemo(() => <World />, []);

  return (
    <Styled.Container>
      <Styled.Navbar>
        <Logo size="lg" />
        <div>
          {!isMobile && <Button onClick={openModal}>Join the waitlist</Button>}
          {!isMobile && (
            <Button
              onClick={() => {
                window.location.href = 'https://app.freightnexus.ai/';
              }}
              action="secondary"
            >
              Log In
            </Button>
          )}
        </div>
      </Styled.Navbar>

      <Styled.Content>
        <Text
          type="display-h1"
          size={isMobile ? 24 : isTablet ? 36 : 50}
          weight={800}
          centered
        >
          Revolutionize your freight experience with FreightNexus.
        </Text>
        <Text type="text-t2" centered>
          Tired of sifting through load boards, endless negotiations, and
          worrying about the reliability of carriers or brokers? Say goodbye to
          inefficiency and hello to a seamless freight experience with
          FreightNexus.
        </Text>
        <Button onClick={openModal}>Join the waitlist</Button>
      </Styled.Content>
      <Styled.GlobeContainer>{memoizedGlobe}</Styled.GlobeContainer>
    </Styled.Container>
  );
};
