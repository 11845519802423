import { BREAKPOINTS } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 75px 55px 75px;

  > :first-child {
    margin-bottom: 12px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 50px 55px 50px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 0 20px 20px 20px;
  }
`;

export const Form = styled.div`
  width: 100%;
  margin: 25px 0;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const CompanyTypes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
`;

export const SubmitButton = styled.div`
  margin-top: 20px;
`;
