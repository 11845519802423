import styled, { css } from 'styled-components';

export interface ButtonProps {
  size?: 'md' | 'lg';
  variant?: 'filled' | 'outlined';
  action?: 'primary' | 'secondary';
  width?: number | string;
  disabled?: boolean;
}

// custom button ui component
export const Button = styled.button<ButtonProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  ${({ width }) => {
    return width
      ? `width: ${typeof width === 'number' ? `${width}px` : width};`
      : 'width: max-content;';
  }}
  cursor: pointer;
  user-select: none;
  border: none; // Remove default border
  white-space: nowrap;
  ${({ size = 'lg' }) => {
    return `
      border-radius: ${size === 'lg' ? '30px' : '10px'};
      padding: ${size === 'lg' ? '13px 23px' : '8px 18px'};
    `;
  }}
  font: ${({ theme }) => theme.textStyles.desktop.button};
  transition:
    filter 0.2s,
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s;
  ${({ variant = 'filled', action = 'primary', disabled = false, theme }) => {
    const backgroundColor =
      variant === 'filled'
        ? theme.components.button[variant][action].buttonColor
        : 'transparent';
    const borderColor = theme.components.button[variant][action].buttonColor;
    const color = theme.components.button[variant][action].textColor;

    return css`
      color: ${color};
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor};
      ${disabled ? 'opacity: 0.2; pointer-events: none;' : ''}

      &:hover {
        filter: brightness(1.05);
      }
    `;
  }}
`;
