import { ITheme } from '../../types';
import { palette } from '../palette';

import * as components from './components';
import { textStyles } from './textStyles';

export const marketing: ITheme = {
  palette,
  textStyles,
  components
};
