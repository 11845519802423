import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface LoadingOverlayProps {
  size?: 'md' | 'lg';
}

export const LoadingOverlay = ({ size = 'md' }: LoadingOverlayProps) => {
  return (
    <Container>
      <Loader size={size} />
    </Container>
  );
};

const Loader = styled.div<LoadingOverlayProps>`
  transform: scale(${({ size = 'md' }) => (size === 'lg' ? '1.5' : '1')});
  cursor: progress;
  position: relative;
  width: 52px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#ef962d, #f7aa52),
    linear-gradient(#f7aa52, #f7c286), linear-gradient(#f7aa52, #f7c286);
  background-size:
    32px 28px,
    12px 20px,
    12px 12px;
  background-position:
    0 0,
    32px 8px,
    40px 16px;

  &:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 5px;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #000;
    box-shadow:
      31px 0 0 -4px #fff,
      31px 0 #000;
    animation: wheelSk 0.75s ease-in infinite alternate;
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    height: 28px;
    width: 28px;
    background-image: linear-gradient(#ef962d 18px, transparent 0),
      linear-gradient(#ef962d 18px, transparent 0),
      linear-gradient(#ef962d 18px, transparent 0);
    background-repeat: no-repeat;
    background-size: 12px 1.6px;
    background-position:
      0px 4px,
      3 14px,
      0px 24px;
    animation: lineDropping 0.75s linear infinite;
  }

  @keyframes wheelSk {
    0%,
    50%,
    100% {
      transform: translatey(0);
    }
    30%,
    90% {
      transform: translatey(-3px);
    }
  }

  @keyframes lineDropping {
    0% {
      background-position:
        40px 4.4px,
        46px 14px,
        42px 24px;
      opacity: 1;
    }
    50% {
      background-position:
        0px 4.4px,
        8px 14px,
        2px 24px;
    }
    60% {
      background-position:
        -12px 4.4px,
        0px 14px,
        -4px 24px;
    }
    75%,
    100% {
      background-position:
        -12px 4.4px,
        -12px 14px,
        -12px 24px;
      opacity: 0;
    }
  }
`;
