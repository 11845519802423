import { BREAKPOINTS } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 12px;
`;

export const Tiles = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  margin-top: 50px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: auto;
    grid-gap: 20px;
  }
`;

export const Tile = styled.div`
  border-radius: 15px;
  border: 3px solid ${({ theme }) => theme.palette.primary};
  width: 475px;
  height: 450px;
  padding: 55px;
  display: grid;
  grid-template-rows: 120px max-content max-content;
  row-gap: 8px;

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    grid-template-rows: 64px max-content max-content;
    width: 350px;
    height: 300px;
    padding: 20px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 100%;
    max-width: 350px;
    height: fit-content;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
`;

export const Icon = styled.img`
  height: 104px;
  width: auto;

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    height: 64px;
  }
`;

export const Description = styled.div`
  max-width: 352px;
  margin-top: 8px;
`;
