import {
  FreightStatus,
  IFreight,
  TemperatureType
} from '@freight-nexus/db/types';

import * as Transport from '../transport';

export const route = '/freight';

export namespace Create {
  export const name = 'Create';

  export interface Input extends Transport.JSONSerializableObject {
    pickupDate: Date;
    deliveryDate: Date;
    email?: string;
    origin: string;
    destination: string;
    temperature: TemperatureType;
    notes: string | null;
    assignedUserId?: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    freight: IFreight;
  }
}

export namespace GetAll {
  export const name = 'GetAll';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    freights: IFreight[];
  }
}

export namespace GetAllUnmatched {
  export const name = 'GetAllUnmatched';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    freights: IFreight[];
  }
}
export namespace UpdateStatus {
  export const name = 'UpdateStatus';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
    status: FreightStatus;
  }

  export interface Output extends Transport.JSONSerializableObject {
    freight: IFreight;
  }
}

export namespace DeleteById {
  export const name = 'DeleteById';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
  }

  export interface Output extends Transport.JSONSerializableObject {}
}
