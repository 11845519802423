import { BREAKPOINTS } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px 120px 24px;

  > :nth-child(2) {
    margin: 30px 0 45px 0;
    max-width: 1000px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    > :nth-child(2) {
      max-width: 700px;
    }
  }
`;
