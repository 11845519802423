import { GlobalStyle, marketingTheme } from '@freight-nexus/ui';
import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { SignUpModal } from './components/SignUpModal';
import { Footer, Hero, Join, Solution, Unlock } from './sections';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

function App() {
  const [showModal, setShowModal] = useState(false);
  return (
    <ThemeProvider theme={marketingTheme}>
      <GlobalStyle />
      <Container>
        <Hero openModal={() => setShowModal(true)} />
        <Unlock />
        <Solution />
        <Join openModal={() => setShowModal(true)} />
        <Footer />
        <SignUpModal visible={showModal} onClose={() => setShowModal(false)} />
      </Container>
    </ThemeProvider>
  );
}

export default App;
