import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import DatePickerRaw from 'react-datepicker';
import styled from 'styled-components';

import Close from '../../icons/close.svg';
import { Input, InputProps } from '../input';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const Anchor = styled.div`
  width: 0;
  height: 100%;
  position: relative;
`;

const CloseButton = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 0 8px 0 2px;

  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
`;

export interface DatePickerProps
  extends React.ComponentProps<typeof DatePickerRaw>,
    InputProps {}

export const DatePicker = (props: DatePickerProps) => {
  return (
    <Container>
      <DatePickerRaw
        {...props}
        shouldCloseOnSelect
        customInput={<Input error={props.error} />}
      />
      <Anchor>
        {props.selected && (
          <CloseButton
            src={Close}
            onClick={() => {
              props.onChange?.(null, undefined);
            }}
          />
        )}
      </Anchor>
    </Container>
  );
};
