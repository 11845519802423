import React from 'react';
import styled from 'styled-components';

import { useDimensions } from '../../hooks/useDimensions';
import { IDeviceTextStyles, IPalette, ITextStyles } from '../../types';

interface TextStyleProps {
  textStyle: keyof IDeviceTextStyles;
  device: keyof ITextStyles;
  secondary?: boolean;
  color?: keyof IPalette;
  size?: number;
  weight?: number;
  centered?: boolean;
}
const TextStyle = styled.div<TextStyleProps>`
  font: ${({ textStyle, device, theme }) =>
    theme.textStyles[device][textStyle]};
  margin: 0;
  padding: 0;
  backface-visibility: hidden;
  ${({ color, secondary, theme }) =>
    color
      ? `color: ${theme.palette[color]}`
      : secondary
        ? `color: ${theme.palette['grey-700']}`
        : ''};
  ${({ size }) => size && `font-size: ${size}px`};
  ${({ weight }) => weight && `font-weight: ${weight}`};
  ${({ centered }) => centered && `text-align: center`};
`;

export interface TextProps extends React.HTMLProps<HTMLParagraphElement> {
  type: keyof IDeviceTextStyles;
  color?: keyof IPalette;
  secondary?: boolean;
  size?: number;
  weight?: number;
  centered?: boolean;
  children?: React.ReactNode;
}
export const _Text = ({ type, children, ...rest }: TextProps) => {
  const { isMobile, isTablet } = useDimensions();
  const regexMatch = type.match(/^display-(h[1-5])/);
  const renderAs = regexMatch && regexMatch.length >= 1 ? regexMatch[1] : 'p';
  return (
    // @ts-ignore
    <TextStyle
      textStyle={type}
      device={isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}
      // @ts-ignore
      as={renderAs}
      {...rest}
    >
      {children}
    </TextStyle>
  );
};

// allow Text to be used in styled components interlopated child selector
export const Text = styled(_Text)``;
