import {
  HydratedMatch,
  ICapacity,
  IFreight,
  IMatch
} from '@freight-nexus/db/types';

import * as Transport from '../transport';

export const route = '/match';

export namespace Create {
  export const name = 'Create';

  export interface Input extends Transport.JSONSerializableObject {
    freightId: string;
    capacityId: string;
    brokerId: string | null;
    carrierId: string | null;
  }

  export interface Output extends Transport.JSONSerializableObject {
    match: IMatch;
  }
}

export namespace GetAll {
  export const name = 'GetAll';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    matches: HydratedMatch[];
  }
}

export namespace DeleteById {
  export const name = 'DeleteById';

  export interface Input extends Transport.JSONSerializableObject {
    id: string;
  }

  export interface Output extends Transport.JSONSerializableObject {}
}

export namespace GetByUserId {
  export const name = 'GetByUserId';

  export interface Input extends Transport.JSONSerializableObject {
    userId: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    matches: HydratedMatch[];
  }
}

export namespace AutoMatch {
  export const name = 'AutoMatch';

  export interface Input extends Transport.JSONSerializableObject {}

  export interface Output extends Transport.JSONSerializableObject {
    dateMatches: {
      load: IFreight;
      trucks: ICapacity[];
    }[];
    locationMatches: {
      load: IFreight;
      trucks: ICapacity[];
    }[];
  }
}
