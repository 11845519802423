import { Transport } from '@freight-nexus/types';

const base = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const request =
  <
    I extends Transport.JSONSerializableObject,
    O extends Transport.JSONSerializableObject
  >(
    route: string,
    name: string
  ) =>
  async (_payload?: I) => {
    try {
      const payload = _payload || ({} as I);

      const url = `${base}${route}/${name}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const data: O | undefined = await response.json();
      if (data?.error) throw new Error(data.error);
      return data;
    } catch (error) {
      return {
        error: (error as Error)?.message
      } as O;
    }
  };
