import { ButtonTheme } from '../../types';
import { palette } from '../palette';

export const button: ButtonTheme = {
  filled: {
    primary: {
      buttonColor: palette.primary,
      textColor: palette.black
    },
    secondary: {
      buttonColor: palette['grey-500'],
      textColor: palette.black
    }
  },
  outlined: {
    primary: {
      buttonColor: palette.primary,
      textColor: palette.primary
    },
    secondary: {
      buttonColor: palette['grey-500'],
      textColor: palette['grey-700']
    }
  }
};
