import React from 'react';
import styled from 'styled-components';

import { Text } from '../text';

export interface InputProps {
  size?: 'lg';
  error?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  height: ${({ size = 'lg' }) => (size === 'lg' ? 44 : 44)}px;
  padding: ${({ size = 'lg' }) => (size === 'lg' ? '12px 12px' : '12px 12px')};
  border-radius: 10px;
  font: ${({ theme, size = 'lg' }) =>
    theme.textStyles.desktop[size === 'lg' ? 'text-t2' : 'text-t3']};
  box-sizing: border-box;
  line-height: 1;
  margin: 0px;

  outline: none;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-500']};

  &:focus {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-600']};
  }
  &:hover {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-600']};
  }
  outline-color: ${({ theme }) => theme.palette['grey-500']};
  &::placeholder {
    color: ${({ theme }) => theme.palette['grey-700']};
    user-select: none;
  }
`;

export const TextArea = styled.textarea<InputProps>`
  resize: vertical;
  width: 100%;
  min-height: 70px;
  padding: 12px;
  border-radius: 10px;
  font: ${({ theme }) => theme.textStyles.desktop['text-t2']};
  box-sizing: border-box;
  line-height: 1;
  margin: 0px;

  outline: none;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-500']};

  &:focus {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-600']};
  }
  &:hover {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.red : theme.palette['grey-600']};
  }
  outline-color: ${({ theme }) => theme.palette['grey-500']};
  &::placeholder {
    color: ${({ theme }) => theme.palette['grey-700']};
    user-select: none;
  }
`;

const GroupContainer = styled.div`
  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const RequiredAsterisk = styled.span`
  color: ${({ theme }) => theme.palette.red};
`;

export interface InputGroupProps {
  header?: string;
  required?: boolean;
  error?: string;
  children: React.ReactNode;
}

export const InputGroup = ({
  header,
  required = false,
  error,
  children
}: InputGroupProps) => {
  return (
    <GroupContainer>
      {header && (
        <Text type="display-h4">
          {header}
          {required ? <RequiredAsterisk>&nbsp;*</RequiredAsterisk> : ''}
        </Text>
      )}
      {children}
      {error && (
        <Text type="text-t3" color="red">
          {error}
        </Text>
      )}
    </GroupContainer>
  );
};
