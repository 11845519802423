import { API } from '@freight-nexus/types';

import { request } from './base';

const route = API.Waitlist.route;

export const signUp = request<
  API.Waitlist.SignUp.Input,
  API.Waitlist.SignUp.Output
>(route, API.Waitlist.SignUp.name);
