import { CompanyType } from '@freight-nexus/db/types';

import * as Transport from '../transport';

export const route = '/waitlist';

export namespace SignUp {
  export const name = 'SignUp';

  export interface Input extends Transport.JSONSerializableObject {
    email: string;
    name: string;
    company: string;
    companyType: CompanyType;
  }
  export interface Output extends Transport.JSONSerializableObject {
    success: boolean;
  }
}
