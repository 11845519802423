import { ITextStyles } from '../../types/theme';

const Font = {
  SansSerif: 'Plus Jakarta Sans, sans-serif'
};

export const textStyles: ITextStyles = {
  desktop: {
    'display-h1': `700 40px ${Font.SansSerif}`,
    'display-h2': `700 24px ${Font.SansSerif}`,
    'display-h3': `600 18px ${Font.SansSerif}`,
    'display-h4': `600 16px ${Font.SansSerif}`,
    'text-t1': `400 20px ${Font.SansSerif}`,
    'text-t2': `400 18px ${Font.SansSerif}`,
    'text-t3': `400 16px ${Font.SansSerif}`,
    button: `600 18px ${Font.SansSerif}`
  },
  tablet: {
    'display-h1': `700 32px ${Font.SansSerif}`,
    'display-h2': `700 24px ${Font.SansSerif}`,
    'display-h3': `600 18px ${Font.SansSerif}`,
    'display-h4': `600 16px ${Font.SansSerif}`,
    'text-t1': `400 16px ${Font.SansSerif}`,
    'text-t2': `400 16px ${Font.SansSerif}`,
    'text-t3': `400 16px ${Font.SansSerif}`,
    button: `600 18px ${Font.SansSerif}`
  },
  mobile: {
    'display-h1': `700 24px ${Font.SansSerif}`,
    'display-h2': `700 20px ${Font.SansSerif}`,
    'display-h3': `600 18px ${Font.SansSerif}`,
    'display-h4': `600 16px ${Font.SansSerif}`,
    'text-t1': `400 16px ${Font.SansSerif}`,
    'text-t2': `400 16px ${Font.SansSerif}`,
    'text-t3': `400 16px ${Font.SansSerif}`,
    button: `600 18px ${Font.SansSerif}`
  }
};
