import { BREAKPOINTS } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 24px;
  background-color: ${({ theme }) => theme.palette.primary};

  > svg {
    margin: 40px 0;
  }
`;

export const Img = styled.img`
  width: 750px;
  height: auto;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  max-width: 974px;
`;
