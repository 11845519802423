import { IUser, UserType } from '@freight-nexus/db/types';

import * as Transport from '../transport';

export const route = '/auth';

export namespace SignUp {
  export const name = 'SignUp';

  export interface Input extends Transport.JSONSerializableObject {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    type: UserType;
  }
  export interface Output extends Transport.JSONSerializableObject {
    user: IUser;
    accessToken: string;
  }
}

export namespace SignIn {
  export const name = 'SignIn';

  export interface Input extends Transport.JSONSerializableObject {
    email: string;
    password: string;
  }

  export interface Output extends Transport.JSONSerializableObject {
    user: IUser;
    accessToken: string;
  }
}
