export const CompanyType = {
  Carrier: 'Carrier',
  Broker: 'Broker',
  Other: 'Other'
} as const;
export type CompanyType = (typeof CompanyType)[keyof typeof CompanyType];
export const UserType = {
  Carrier: 'Carrier',
  Broker: 'Broker'
} as const;
export type UserType = (typeof UserType)[keyof typeof UserType];
export const FreightStatus = {
  Unmatched: 'Unmatched',
  Matched: 'Matched'
} as const;
export type FreightStatus = (typeof FreightStatus)[keyof typeof FreightStatus];
export const TemperatureType = {
  Frozen: 'Frozen',
  Refrigerated: 'Refrigerated',
  Dry: 'Dry'
} as const;
export type TemperatureType =
  (typeof TemperatureType)[keyof typeof TemperatureType];
export const EmailStatus = {
  Unprocessed: 'Unprocessed',
  Processed: 'Processed'
} as const;
export type EmailStatus = (typeof EmailStatus)[keyof typeof EmailStatus];
export const MatchStatus = {
  Neutral: 'Neutral',
  BrokerApproved: 'BrokerApproved',
  CarrierApproved: 'CarrierApproved',
  BothApproved: 'BothApproved',
  BrokerRejected: 'BrokerRejected',
  CarrierRejected: 'CarrierRejected',
  BothRejected: 'BothRejected'
} as const;
export type MatchStatus = (typeof MatchStatus)[keyof typeof MatchStatus];
